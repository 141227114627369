@import 'global.scss';

.hovers {
  width: 100%;
  height: 100%;
  mix-blend-mode: color; // 'saturation',
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  cursor: pointer;
  @include disable-grab-ghost();
}

