* {
  -webkit-touch-callout:none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  /*
  perspective: 1000px;
  backface-visibility: hidden;
  transform: rotateZ(360deg) translateZ(0);
  */
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

#root>div{
  position: relative;
}
