/*
fade-appear, fade-appear-active, fade-appear-done
fade-enter, fade-enter-active, fade-enter-done
fade-exit, fade-exit-active, fade-exit-done
*/
.open-enter {
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  transition: opacity 600ms ease-in 600ms;
}
.open-enter.open-enter-active {
  opacity: 1;
  transition: opacity 600ms ease-in 600ms;
}
.fade-enter-done {
  opacity: 1;
}
.open-exit {
  top:0;
  left: 0;
  position: absolute;
  opacity: 1;
  transition: opacity 600ms ease-out;
}
.open-exit.open-exit-active {
  opacity: 0;
  transition: opacity 600ms ease-out;
}
.fade-exit-done {
  opacity: 0;
}
/* */
.fade-enter {
  position: absolute;
  z-index: 999;
  opacity: 0;
  transform: scale(0.1);
  overflow: visible !important;
}
.fade-enter::before {
  content: '';
  position: absolute;
  left: 50%;
  width: 100vw;
  height: 100vw;
  z-index: 1;
  top: calc(50% - 50vw - 20vw);
  border-radius: 50%;
  border: solid 20vw rgb(255, 255, 255);
  background: transparent;
  transform: translate(-50%, 0) scale(1);
  opacity: 1;
}
.fade-enter::after {
  content: '';
  position: absolute;
  left: 50%;
  opacity: 1;
  width: 110vw;
  height: 110vw;
  z-index: -1;
  top: calc(50% - 50vw - 10vw);
  border-radius: 50%;
  background-color:rgb(163, 203, 228);
  transform: translate(-50%, 0) scale(1);
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-out, transform 600ms ease-in;
  transform: scale(1.0);
}
.fade-enter.fade-enter-active::before {
  transform: translate(-50%, 0) scale(1.15);
  opacity: 0;
  transition: transform 600ms ease-out, opacity 600ms ease-out;
}
.fade-enter.fade-enter-active::after {
  transform: translate(-50%, 0) scale(1.15);
  opacity: 0;
  transition: transform 600ms ease-out, opacity 600ms ease-out;
}
.fade-enter-done {
  opacity: 1;
}
.fade-exit {
  opacity: 1;
  position: absolute;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 900ms ease-out;
}
.fade-exit-done {
  opacity: 1;
}
.fade-with-white-background::after {
  background-color: white !important;
}

/* reverse */
.reverse.fade-enter {
  opacity: 0;
  transition: none;
  position: absolute;
  z-index: 998;
}
.reverse.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.reverse.fade-enter::before {
  opacity: 1;
  transform: translate(-50%, 0) scale(1.15);
  background-color:rgb(163, 203, 228);
}
.reverse.fade-enter::after {
  opacity: 1;
  transform: translate(-50%, 0) scale(1.15);
  background-color: transparent;
}
.reverse.fade-enter.fade-enter-active::before {
  opacity: 0;
  transform: translate(-50%, 0) scale(0.0);
  transition: transform 600ms ease-out, opacity 1200ms ease-out;
}
.reverse.fade-enter.fade-enter-active::after {
  opacity: 0;
  transform: translate(-50%, 0) scale(0.0);
  transition: transform 600ms ease-out, opacity 1200ms ease-out;
}
.reverse.fade-enter-done {
  opacity: 1;
}
.reverse.fade-exit-done {
  opacity: 0;
}
