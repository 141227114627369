
@import 'global.scss';

$red: #CC0033;
$ps-color: grey;
$height: 200px;

.multipleModal {
  // overflow: hidden;
  font-size: 16px;
  display: flex;
  position: absolute;
  // border: 1px solid white;
  width: 60vw;
  max-width: 600px;
  @media (max-width: 720px) {
    max-width: calc(100vw - 120px) !important;
  }
  height: 200px;
  border-radius: 20px;
  padding: 10px;
  // background: rgb(235, 235, 235);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  background: white;
  z-index: 999;
  bottom: 10vh;
  :global {
    .col {
      overflow: hidden;
    }
    .page-slider {
      display: block;
      position: absolute;
      height: 40px;
      width: 100%;
      top: calc(100px - 10px);
      left: 0;
      z-index: -1;
      >span {
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
        // background: red;
        
        &:nth-child(1) {
          left: -45px;
          top: 0;
          position: absolute;
          background: white;
          width: 40px;
          height: 40px;
          &::before {
            width: 10px;
            height: 10px;
            position: absolute;
            z-index: 1;
            opacity: 0.5;
            left: calc(40px / 2 - 5px); 
            top: calc(40px / 2 - 7px);
            border-left: solid 3px $ps-color;
            border-top: solid 3px $ps-color;
            content: '';
            transform: /* scaleX(0.5) */ rotate(-45deg);
          }
        }
        &:nth-child(2) {
          right: -45px;
          top: 0;
          position: absolute;
          background: white;
          width: 40px;
          height: 40px;
          &::before {
            width: 10px;
            height: 10px;
            position: absolute;
            z-index: 1;
            opacity: 0.5;
            right: calc(40px / 2 - 5px); 
            top: calc(40px / 2 - 7px);
            border-right: solid 3px $ps-color;
            border-bottom: solid 3px $ps-color;
            content: '';
            transform: /* scaleX(0.5) */ rotate(-45deg);
          }
        }
        &:hover {
          &::before {
            opacity: 1.0 !important;
          }
        }
      }
    }  
    .row {
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      &.footer {
        max-height: 30px;
      }
      &.header {
        // max-height: 20px;
        flex: 0 1 auto;
        .col:nth-child(2) {
          min-width: 70%;
          align-items: flex-start;
        }
      }
      &.bodyParent {
        max-height: calc(100% - 30px);
        .body {
          overflow: hidden;
          display: flex;
          flex-direction: row;
          width: calc(100% - 5px);
          height: 100%;
          .col:nth-child(2) {
            overflow: hidden;
            div {
              @include scrollable;
            }
          }
          .col:nth-child(1) {
            max-width: 30%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            div {
              width: 100%;
              height: 100%;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }
          }
          p {
            padding: 2px;
            padding-top: 5px;
            padding-right: 15px;
          }
          img {
            // padding: 14px;
            max-width: calc(100% - 20px);
            max-height: calc(100% - 20px);
            padding: 0;
          }
        }
      }
    }
    .col {
      &.link {
        line-height: 10px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        >i {
          color: #999999;
          a {
            all: unset !important;
            color: $red !important;
            text-decoration: none !important;
            cursor: pointer !important;
          }
        }
      }
      &.close {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 1;
        button {
          background: none;
          display: flex;
          border: none;
          justify-content: center;
          align-items: center;
          font-size: 25px;
          padding: 0;
          margin: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
          color: #999999;
          span {
            transform: rotate(45deg);
          }
        }
      }
      width: 100%;
      display: flex;
      flex-direction: column;
      &.slider {
        max-width: 30%;
        padding-left: 5px;
        .row {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          .slide {
            width: 14px;
            height: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              border: solid 1px $red;
              &.active {
                background: $red;
              }
              border-radius: 50%;
              width: 10px;
              height: 10px;
              display: inline-block;
              cursor: pointer;
              background: white;
              border: 1px solid lightgray;
            }
          }
        }
      }
      h1, h2 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        padding-right: 20px;
      }
      h1 {
        margin-left: 8px;
        color: #333333;
        font-family: "Metropolis-Bold";
        line-height: 14px;
        height: 15px;
        text-transform: uppercase;
        font-size:14px;
      }
      h2 {
        color: $red;
        font-family: "Metropolis-Bold";
        line-height: 14px;
        font-size: 14px;
      }
      p {
        margin: 0;
        padding: 0;
        color: #999999;
        font-size: 14px;
      }
    }
  }
}