@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
@mixin animate($name, $duration, $delay) {
  -webkit-animation-name: $name;
  -moz-animation-name: $name;
    -o-animation-name: $name;
      animation-name: $name;
  -webkit-animation-duration: $duration;
    -moz-animation-duration: $duration;
      -o-animation-duration: $duration;
          animation-duration: $duration;
  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
    -o-animation-delay: $delay;
        animation-delay: $delay;
}

@mixin scrollable {
  &::-webkit-scrollbar {
    width: 8px;
	  // background-color: #F5F5F5;
    position: absolute;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
	  border-radius: 4px;
	  background-color: #F5F5F5;
    margin-top: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,.3);
    background-color: #CC0033;
  }
  overflow-x: hidden;
  overflow-y: overlay;
}

@mixin disable-grab-ghost() {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;

  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}
