$grey: #999999;
.tutoPanel {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  color: $grey;
  >div {
    overflow: hidden;
    width: 40vh;
    height: 40vh;
    background: white;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    p {
      padding: calc(2px + 5%);
      margin: 0;
      display:flex;
      flex:1;
      text-align: center;
      font-size: 14px;
      max-height: 30%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    div {
      display: flex;
      flex: 1;
    }
    :global {
      .image {
        display: flex;
        flex: 1;
        width: 100%;
        align-items: center;
        justify-content: center;
        span {
          width: 50%;
          height: 50%;
          margin-left: -5%;
          margin-top: 15%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
        // margin-left: -8px;
      }
    }
  }
  button {
    margin-top:8px;
    background: white;
    height: 40px;
    width: 20vh;
    border-radius: 20px;
    border: none;
    color: $grey;
    cursor: pointer;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    font-family: "Metropolis-Black" !important;
    &:hover {
      color: black !important;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
    }
  }
}
