@import 'global.scss';
$red: #CC0033;
$center-color: rgb(0, 89, 255);
$drag-indicator-color: rgb(255, 115, 0);
@include keyframes(fadeIn) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@include keyframes(onclick) {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.hoverLayer {
  opacity: 0;
  transition: opacity 0.25s ease-in;
}
.switchButton360, .dataFlowButton {
  position: absolute;
  :global {
    z-index:2 !important;
    right: 20px;
    bottom: 220px; // calc(50vh + 20px);
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer !important;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    background: white;
    .inner {
      display: block;
      position: relative;
      width: 90%;
      height: 90%;
      margin: 5%;
      margin-left: calc(5% + 2px);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;  
    }
  }
}
.DFActive {
  :global {
    background: $red !important;
    .inner {
      filter: grayscale(100%) brightness(2);
    }
  }
}
.dataFlowButton {
  right: 20px;
  bottom: 160px;
}
.area :global {
  position: relative;
  width: 100vw; // tmp
  height: 100vh;  // tmp
  span {
    display: none;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    // pointer-events: auto;
  }
  svg {
    width: 100vw;
    height: 100vh;
    //pointer-events: fill;
    polygon {
      cursor: pointer;
    }
    // background-color: rgba(0, 0, 255, 0.486);
  }
}

.isoContainer :global {
  display: flex;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  .data-flow {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    // background-color:rgba(0, 0, 0, 0.338);
    z-index: 1;
    .frame {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background-position: center;
      background-repeat: no-repeat;
      // background-size: contain;
      z-index: -1;
      &.frame-in {
        z-index: 2 !important;
        opacity: 1;
        transition: opacity 250ms ease-in;
      }
      &.frame-out {
        opacity: 0;
        transition: opacity 250ms ease-out;
      }
    }
  }
  .activate-flow {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index:999;
    button {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }
  .zoom-button {
    display: flex;
    position: absolute;
    flex-direction: column;
    bottom: 60px;
    right: 20px;
    z-index:2;
    button {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
      background-color: white; //rgb(255, 15, 95);
      background-repeat: no-repeat;
      background-size: 50%;
      background-position: center;
      color: grey;
      border: none;//1px solid rgb(168, 0, 0);
      width: 40px;
      height: 40px;
      text-align: center;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include disable-grab-ghost();
      &:nth-child(1) {
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
      }
      /*
      &:nth-child(2) {
        height: 20px;
      }
      */
      &:nth-child(2) {
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }
  }
  .map {
    :local {
      animation-fill-mode: forwards;
      @include animate(fadeIn, 2.0s, 0.0s);
    }
    position: relative;
    width: 100%;
    height: auto;
    max-height:100vh;
    overflow: visible; // hidden;
    @include disable-grab-ghost();
    &::before {
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      content:'';
      width: 15px;
      height: 15px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(calc(-100% + 2px)) translateY(calc(-100% + 2px));
      z-index: 3;
      border-bottom: dotted 2px $center-color;
      border-right: dotted 2px $center-color;
      mix-blend-mode: hard-light;
      pointer-events: none;
    }

    &::after {
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      content:'';
      width: 15px;
      height: 15px;
      position: absolute;
      left: 50%;
      top: 50%;
      border-top: dotted 2px $center-color;
      border-left: dotted 2px $center-color;
      mix-blend-mode: hard-light;
      z-index: 3;
      pointer-events: none;
    }

    &.no-cross {
      &::after, &::before {
        opacity: 1;
      }
    }

    .drag-indicator {
      width: 20px;
      height: 20px;
      background:$drag-indicator-color;
      border-radius: 50%;
      position: absolute;
      top: -10px;
      left: -10px;
      z-index: 2;
      @include disable-grab-ghost();
      display: flex;
      align-items: center;
      justify-content: center;
      .head {
        @include disable-grab-ghost();
        background: $drag-indicator-color;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        position: absolute;
        z-index: 2;
        transform: translate(-5px, -5px);
      }
      svg {
        position: absolute;
        width: 0px;
        height: 0px;
        background: none;
        z-index: 2;
        @include disable-grab-ghost();
        line {
          stroke: $drag-indicator-color;
        }
      }
    }

    .click-indicator {
      pointer-events: none;
      width: 50px;
      height: 50px;
      background: rgb(109, 177, 255);
      border-radius: 50px;
      position: absolute;
      top: -25px;
      left: -25px;
      z-index: 2;
      &::after {
        pointer-events: none;
        content:'';
        position:absolute;
        width: 80%;
        height: 80%;
        top: 10%;
        left: 10%;
        border-radius: 50%;
        background: rgb(65, 154, 255);
      }
      &.active:local {
        animation-fill-mode: forwards;
        @include animate(onclick, 0.5s, 0.0s);
      }
    }
    display: flex;
    align-items: center;
    .layers {
      // -webkit-filter: blur(0px);
      // filter: none;
      pointer-events: none;
      z-index:1;
      transform: scale(1) translate(0px, 0px); // translateZ(0);
      transition-timing-function: ease-out;
      transition-property: transform;
      transition-duration: 0.5s;
      position: relative;
      // position: absolute;
      height: auto;

      // width: 100%;
      width: 80%;
      margin-left: 10%;
      @include disable-grab-ghost();
      
      /*
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      */

      .limit {
        /*
        &._0 { left: -100px; }
        &._1 { left: 100%; }
        &._2 { bottom: -100px; }
        &._3 { bottom: 0; left: 100%; }
        */
        &._0 { left: 0px; }
        &._1 { left: calc(100% - 100px); }
        &._2 { bottom: 0px; }
        &._3 { bottom: 0px; left: calc(100% - 100px); }
        // background: red;
        position: absolute;
        width: 100px;
        height: 100px;
        z-index: 2;
      }
      img.background, img.big-background {
        width: 100%;
        opacity: 1.0;
        display: block;
        position: relative;
        @include disable-grab-ghost();
        -webkit-font-smoothing: antialiased;
        // -webkit-filter: blur(0px);
        // filter: none;
        /* mix-blend-mode: screen; */
        transition: opacity 0.3s ease-in;
        pointer-events: none;
        // background-blend-mode: multiply, screen;
      }
      img.big-background {
        position: absolute !important;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        mix-blend-mode:normal;
        z-index:-1;
        // width: 3110px;
        width: 253%;
        // width: 2400px; // <- small
      }
      /*
      &::before {
        box-shadow: 0 0 5px 5px white inset;
        display:block;
        content:'';
        position:absolute;
        width:100%;
        height:100%;
        z-index: 1;
      }
      */
    }
  }
}
