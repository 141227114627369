@import 'global.scss';
$blue: #051F4F;
$grey: #999999;
$time1: 8.0s;
$time2: 5.0s;
@include keyframes(fade-in-out) {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.tutoBubbleMap {
  opacity: 0;
  animation-fill-mode: forwards;
  @include animate(fade-in-out, $time1, 0.0s);
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 11;
  :global {
    .bubble {
      font-family: "Metropolis-Black", serif;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-50%) translateY(-50%);
      height: 50px;
      // width: auto;
      flex:1;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      z-index: 3;
      text-transform: uppercase;
      font-weight: bold;
      >div {
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 260px;
        height: 100%;
        padding: 10px;
        background: white;
        position: relative;
        &::before {
          content: '';
          z-index: -2;
          position: absolute;
          border-radius: 25px;
          width: 100%;
          height: 100%;
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
        }
        &::after {
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
          content: '';
          background: white;
          position: absolute;
          width: 30px;
          height: 30px;
          bottom: -15px;
          left: 50%;
          transform: translateX(-15px) translateY(-2px) scaleX(0.7) rotate(45deg);
          border-bottom-right-radius: 8px;
          z-index: -1;
        }
        span {
          color: $blue;
          word-break: keep-all;
          white-space: nowrap;
          display: inline-block;
        }
      }
    }
  }
}

.tutoMapAnimated {
  opacity: 0;
  animation-fill-mode: forwards;
  @include animate(fade-in-out, $time2, 0.0s);
}
.tutoMap {
  :global {
    position: absolute;
    z-index: 11;
    width: 100%;
    height: 100%;
    // pointer-events: none;
    div {
      font-family: "Metropolis-Regular";
      font-size: 14px;
      position: absolute;
      &:nth-child(1) {
        bottom: 227px;
        right: 80px;
      }
      &:nth-child(2) {
        bottom: 167px;
        right: 80px;
      }
      &:nth-child(3) {
        bottom: 107px;
        right: 80px;
      }
      &.force-first {
        bottom: 167px !important;
        right: 80px !important;       
      } 
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      height: 30px;
      background: white;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
      border-radius: 15px;
      span {
        word-break: keep-all;
        white-space: nowrap;
        display: inline-block;
        padding: 10px;
        color: $grey;
      }
    }
  }
}
