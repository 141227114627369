@font-face {
  font-family: "Metropolis-Regular";
  src: local("Metropolis-Regular"), url("./assets/fonts/Metropolis-Regular.otf");
}
@font-face {
  font-family: "Metropolis-Black";
  src: local("Metropolis-Black"), url("./assets/fonts/Metropolis-Black.otf");
}
@font-face {
  font-family: "Metropolis-Medium";
  src: local("Metropolis-Medium"), url("./assets/fonts/Metropolis-Medium.otf");
}
@font-face {
  font-family: "Metropolis-Thin";
  src: local("Metropolis-Thin"), url("./assets/fonts/Metropolis-Thin.otf");
}
@font-face {
  font-family: "Metropolis-Bold";
  src: local("Metropolis-Bold"), url("./assets/fonts/Metropolis-Bold.otf");
}

.app {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
  font-family: "Metropolis-Regular";
}
