@import 'global.scss';
$red: #CC0033;
$blue: #65AFFC;
$grey: #999999;

@include keyframes(fadeon) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.titlePanel {
  @include animate(fadeon, 0.5s, 0.0s);
  font-family: "Metropolis-Black", serif;
  position: absolute;
  top: 16px;
  left: 0;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // background: white;
  z-index: 3;
  text-transform: uppercase;
  font-weight: bold;
  >div {
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25%;
    max-width: 260px;
    height: 100%;
    padding: 10px;
    background: white;
    position: relative;
    &::before {
      content: '';
      z-index: -2;
      position: absolute;
      border-radius: 25px;
      width: 100%;
      height: 100%;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    }
    &::after {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
      content: '';
      background: white;
      position: absolute;
      width: 30px;
      height: 30px;
      bottom: -15px;
      left: 50%;
      transform: translateX(-15px) translateY(-2px) scaleX(0.7) rotate(45deg);
      // border-radius: 8px;
      border-bottom-right-radius: 8px;
      z-index: -1;
    }
    span {
      color: $grey;
    }
  }
}

.room :global {
  width: 100vw;
  height: 100vh;
  position: relative;
  
  .info-hot-spot {
    font-family: "Metropolis-Black";
    text-transform: uppercase;
    position: absolute;
    display: flex !important;
    width: max-content;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: -15px;
    margin-top: -15px;
    transform: translateX(-100px) translateY(-100px);
    z-index: 999;
    &.force-top-layer {
      z-index: 1001;
    }
    filter: grayscale(0%) brightness(1);
    opacity: 1;
    &.force-disabled {
      pointer-events: none;
      opacity: 0.9;
      filter: grayscale(10%) brightness(1.05);
    }
    transition: filter 0.5s ease-out, opacity 0.5s ease-out;
    cursor: pointer;
    i {
      text-transform: none;
      font-family: "Metropolis-Regular";
      font-style: normal !important;
      color: $blue;
      display: inline-block;
      background-color: white;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      font-size: 18px;
      line-height: 32px;
      transform: scale(1.0);
      transition: transform 0.25s ease-in, color 0.25s ease-in, background-color 0.25s ease-in;
      &::after {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 22px;
        height: 22px;
        z-index: 1;
        border: solid 2px $blue;
        border-radius: 50%;
        transition: border 0.25s ease-in;
      }
    }
    >div {
      max-width: 0px;
      padding-left: 0px;
      padding-right: 0px;
      opacity: 0;
      overflow: hidden;
      color: white;
      display: flex;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $red;
      border-radius: 13px;
      margin-left: 5px;
      transition: opacity 0.25s ease-in;
      box-shadow: 0px 0px 2px rgba(255, 255, 255, 0.5);
      font-size: 12px;
      span {
        display: inline-block;
      }
    }
    &.no-click {
      >div {
        color: white !important;
        background-color: $grey !important;
      }
      i {
        background-color: $grey !important;
        color: white !important;
        &::after {
          border: solid 2px white !important;
        }
      }
    }
    &:hover, &.active {
      i {
        color: white;
        background-color: $red;
        &::after {
          border: solid 2px white;
        }
      }
      z-index: 1000 !important;
      >div {
        opacity: 1;
        max-width: unset;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    &.active {
      i {
        transform: scale(1.2);
      }
    }
  }
  .zoom-button {
    z-index:3;
    display: flex;
    position: absolute;
    flex-direction: column;
    bottom: 60px;
    right: 20px;
    button {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
      background-color: white; //rgb(255, 15, 95);
      background-repeat: no-repeat;
      background-size: 50%;
      background-position: center;
      color: grey;
      border: none;//1px solid rgb(168, 0, 0);
      width: 40px;
      height: 40px;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
      &:nth-child(1) {
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
      }
      /*
      &:nth-child(2) {
        height: 20px;
      }
      */
      &:nth-child(2) {
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }
  }
  .room-view {
    z-index: 1;
  }
}