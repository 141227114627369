.lottie {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  pointer-events: none;
  :global {
    svg {
      transform: none !important;
    }
  }
}
